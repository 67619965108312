import './App.css';
import AppRouter from "./router/AppRouter";
import { ToastContainer } from "react-toastify";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-calendar/dist/Calendar.css';


function App() {
  return (
    <div className="App">
      <AppRouter/>
      <ToastContainer/>
    </div>
  );
}

export default App;
