import React from "react";
import Organizasyon from "../../images/dugun1.png";
import Restoran from "../../images/restoran_logo.png";


const Links = () => {
  return (
    <div className="d-flex justify-content-center flex-wrap  ">
      
      <div className="m-3 ">
        <img
          src={Organizasyon}
          alt=""
          style={{ width: "200px", height: "200px" }}
          className="rounded-circle m-2"
        />
        <a href="/organizasyonlar" className=" text-decoration-none text-danger ">
          <h2>ORGANİZASYONLAR</h2>
        </a>
      </div>


      <div className="m-3 ">
        <img
          src={Restoran}
          alt=""
          style={{ width: "200px", height: "200px" }}
          className="rounded-circle m-2"
        />
        <a href="/restoran" className=" text-decoration-none text-danger ">
          <h2>RESTORAN</h2>
        </a>
      </div>
    </div>
  );
};

export default Links;
