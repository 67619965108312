import React from 'react';
import Tuzukpdf from "../../staticfiles/ÇUKUROVA KULÜBÜ DERNEĞİ TÜZÜK.pdf";
import Dilekce from "../../staticfiles/DILEKCE.pdf";
import Uyeform from "../../staticfiles/UYE GIRIS FORMU.pdf";
import Bursbasvuru from "../../staticfiles/BURS BAŞVURU EVRAKLARI.pdf";
import "./Tuzuk.css"
const Tuzuk = () => {
  return (
    <div style={{ marginTop: "25vh", marginBottom:"22vh"}}>
      <h1 className="header mt-5">DOKÜMANLAR</h1>
      <a href={Tuzukpdf} target="blank"><h3 className='d-inline'>TÜZÜK 2022</h3></a><br /><br />
      <a href={Uyeform} target="blank"><h3 className='d-inline'>ÜYE BAŞVURU FORMU</h3></a><br /><br />
      <a href={Bursbasvuru} target="blank"><h3 className='d-inline'>BURS BAŞVURU EVRAKLARI</h3></a><br /><br />
      <a href={Dilekce} target="blank"><h3 className='d-inline'>BOŞ DİLEKÇE FORMATI</h3></a>
    </div>
  )
}

export default Tuzuk