import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Galeri.css"



const Galeri = () => {
  
  const baseUrl =`${process.env.REACT_APP_BASE_URL}/galeri`;
  const [photos, setPhotos] = useState([]);

  const fetchData = async () => {
    const response = await axios.get(baseUrl)
      .then(res=>res.data)
      .catch(error=>console.log(error))   
      setPhotos(response.results.reverse());

  };


  useEffect(() => {
    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  return (
    <div style={{ marginTop: "24vh" }}>
      <h1 className="header mt-5">GALERİ</h1>
      <div className=" d-flex justify-content-center flex-wrap">
      {photos?.filter((item)=>item.title !== "organizasyon").map((photo)=> <div>      
        <img src={photo.image} alt="galeri_photo" className="foto"  key={photo.id}/>
        </div>
        )}
      </div>
    </div>
  );
};

export default Galeri