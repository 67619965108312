import React from "react";
import Links from "../../components/links/Links";
import "./Anasayfa.css";

import CarouselPage from "../../components/carousel/Carousel";
import AtaturkCards from "../../components/ataturk_card/AtaturkCards";
import BaskanCards from "../../components/baskan_card/BaskanCards";
import DuyurularComp from "../../components/duyurular/DuyuruCards";

import BizeUlasın from "../../components/iletisim/BizeUlasın";
import IletisimForm from "../../components/iletisim/IletisimForm";

const Anasayfa = () => {
  return (
    <div className="container">
      <div className="first_page">
        <AtaturkCards className="ataturk" />
        <CarouselPage className="carrousel" />
        <BaskanCards className="baskan" />
      </div>

      <div className="second_page">
        <CarouselPage className="carrousel" />
      </div>

      <Links />
      <DuyurularComp />

      <div className="d-flex justify-content-center flex-wrap">
        <BizeUlasın />
        <IletisimForm />
      </div>
    </div>
  );
};

export default Anasayfa;
