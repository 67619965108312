import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./Restoran.css";
import { GrInstagram } from "react-icons/gr";
// ? İMAGEs
import Bgimg from "../../images/restoran/random6.jpg";
import BaslangıcImg from "../../images/restoran/baslangıclar.jpg";
import AraSıcakImg from "../../images/restoran/arasıcaklar.jpg";
import SalataImg from "../../images/restoran/salatalar.jpg";
import EtImg from "../../images/restoran/et.jpg";
import BalıkImg from "../../images/restoran/balık.jpg";
import TavukImg from "../../images/restoran/tavuk.jpg";
import FırınImg from "../../images/restoran/fırın.jpg";
import SteakImg from "../../images/restoran/steak.jpg";
import IcecekImg from "../../images/restoran/icecekler.jpg";
import MeyveImg from "../../images/restoran/meyve.jpg";
import KahvaltıImg from "../../images/restoran/kahvaltı.jpg";

const Restoran = () => {
  const navigate = useNavigate();
  const baseUrl = `${process.env.REACT_APP_BASE_URL}/restoran`;
  const [menu, setMenu] = useState([]);

  const data_list = [
    {
      id: 0,
      title: "BAŞLANGIÇ",
      img: BaslangıcImg,
      link: "/baslangic",
      filter_item: "BAŞ",
    },
    {
      id: 1,
      title: "ARA SICAKLAR",
      img: AraSıcakImg,
      link: "/arasicak",
      filter_item: "ARA",
    },
    {
      id: 2,
      title: "SALATALAR",
      img: SalataImg,
      link: "/salatalar",
      filter_item: "SAL",
    },
    { id: 3, title: "ET", img: EtImg, link: "/et", filter_item: "ET" },
    {
      id: 4,
      title: "BALIK",
      img: BalıkImg,
      link: "/balik",
      filter_item: "BAL",
    },
    {
      id: 5,
      title: "TAVUK",
      img: TavukImg,
      link: "/tavuk",
      filter_item: "TAV",
    },
    {
      id: 6,
      title: "FIRIN MENÜ",
      img: FırınImg,
      link: "/firin",
      filter_item: "FIR",
    },
    {
      id: 7,
      title: "STEAK MENÜ",
      img: SteakImg,
      link: "/steak",
      filter_item: "STE",
    },
    {
      id: 8,
      title: "İÇECEKLER",
      img: IcecekImg,
      link: "/icecekler",
      filter_item: "İÇE",
    },
    {
      id: 9,
      title: "MEYVE / TATLI",
      img: MeyveImg,
      link: "/meyve",
      filter_item: "MEY",
    },
    {
      id: 10,
      title: "KAHVALTI",
      img: KahvaltıImg,
      link: "/kahvalti",
      filter_item: "KAH",
    },
  ];

  //? Fetch  Data
  const fetchData = async () => {
    const response = await axios
      .get(baseUrl)
      .then((res) => res.data)
      .catch((error) => console.log(error));
    setMenu(response.results);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const moreClick = (e) => {
    const select_id = e.target.id;
    navigate("/details", { state: { select_id } });
  };

  return (
    <div style={{ marginTop: "18vh" }} className="restoran_container">
      <div className="bg">
        <img src={Bgimg} alt="" className="bgimg" />
        <div className="bg_col"></div>
      </div>

      <h1 className="header pt-5 ">KULÜP RESTORAN</h1>
      <h3 className="text-white bg-danger d-inline p-2 rounded">
        REZERVASYON:0533 447 0443
      </h3>
      <br />
      <a href="https://www.instagram.com/kuluprestoranadana" target="blank">
        <GrInstagram
          className="icon m-3"
          style={{ width: "60px", height: "60px" }}
        />
      </a>

      {/* KARTLAR */}
      <div className="cards_container d-flex justify-content-center flex-wrap">
        {data_list.map((item, index) => (
          <div className="menu_cards m-5" key={index} onClick={moreClick}>
            <img
              src={item.img}
              id={item.id}
              alt="menu_image"
              className="card_image"
            />
            <h2 className="eats_name" id={item.id}>
              {item.title}
            </h2>
          </div>
        ))}
      </div>

      {/* MENÜLER */}
      <div className="m-4">
        {data_list.map((item, index) => (
          <div className="menus" key={index}>
            <h1 className="header head_1">{item.title}</h1>
            <img src={item.img} alt="menu_img" className="menu_img m-auto" />
            {menu
              .filter((title) => title.order_type === item.filter_item)
              .map((item, index) => (
                <div className="eats" key={index}>
                  <div className="menu_line">
                    <div className="eat_name">{item.title}</div>
                    {/* <div className="price">{item.price} ₺</div> */}
                  </div>
                  <div className="text_context">{item.comment}</div>
                </div>
              ))}

            <div className="text-white mt-5">
              <p>* Servis Elemanından Bilgi Alınız.</p>
              <h5>REZERVASYON : 0533 447 0443</h5>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Restoran;
