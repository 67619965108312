import React from 'react'
import Card from 'react-bootstrap/Card';
import Ataturk from "../../images/atatürk.png"


const AtaturkCards = () => {
  return (
    <div >
    <Card style={{ width: "16rem"}} >
      <Card.Img  src={Ataturk} className="card_img mt-3" style={{ height:"12rem"}}/>
      <Card.Body className="card_body">
        <h6 >MUSTAFA KEMAL ATATÜRK <br /> 1881-193∞</h6>
        <p>          
          "Bende bu vekayin ilk hissi teşebbüsü bu memlekette, bu güzel Adana'da doğmuştur."
        </p>            
      </Card.Body>
    </Card>
  </div>
  )
}

export default AtaturkCards