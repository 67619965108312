import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./Tarihce.css";
import Bayrak from "../../images/bayrak.png";

const Tarihce = () => {
  const navigate = useNavigate();

  const baseUrl = `${process.env.REACT_APP_BASE_URL}/history`;
  const [tarihce, setTarihce] = useState([]);

  //? Fetch  Data

  const fetchData = async () => {
    const response = await axios
      .get(baseUrl)
      .then((res) => res.data)
      .catch((error) => console.log(error));
    setTarihce(response.results);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="tarihce">
      <h1 className="header"> TARİHÇE </h1>
      {tarihce?.map((item) => (
        <div className="tanıtım">

          <p style={{ fontSize: "20px" }}>{item.comment}</p>
        </div>
      ))}

      <div
        className="btn btn-danger mb-5"
        onClick={() => navigate("/baskanlar")}
      >
        ESKİ BAŞKANLARIMIZ
      </div>

      <div className="kurucu_box">
        <img src={Bayrak} alt="background_img_bayrak" className="bg_img" />
        <div className="kurucu">
          <h2>KURUCU ÜYELERİMİZ</h2>
          <table>
            <thead>
              <tr>
                <th>ADI SOYADI</th>
                <th>MESLEĞİ</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Zahit AKDAĞ</td>
                <td>Tüccar</td>
              </tr>
              <tr>
                <td>Mehmet ANDIRIN</td>
                <td>Çiftçi</td>
              </tr>
              <tr>
                <td>Melahat ARSAVA</td>
                <td>Yüksek Mimar</td>
              </tr>
              <tr>
                <td>Enver BATUMLU</td>
                <td>Gazeteci</td>
              </tr>
              <tr>
                <td>Senai DIBLAN</td>
                <td>Öğretmen</td>
              </tr>
              <tr>
                <td>Mehmet KARABUCAK</td>
                <td>Fabrikatör - Çiftçi</td>
              </tr>
              <tr>
                <td>Mahmut KURDAK</td>
                <td>Öğretmen</td>
              </tr>
              <tr>
                <td>Mustafa KURDAK</td>
                <td>Çiftçi</td>
              </tr>
              <tr>
                <td>Kazım KESER</td>
                <td>Bankacı</td>
              </tr>
              <tr>
                <td>Nihat ORAL</td>
                <td>Gazeteci - Çiftçi</td>
              </tr>
              <tr>
                <td>İhsan PANCAROĞLU</td>
                <td>Çiftçi</td>
              </tr>
              <tr>
                <td>Kemal PEKÜN</td>
                <td>Fabrikatör - Çiftçi</td>
              </tr>
              <tr>
                <td>Ali SEPİCİ</td>
                <td>Çiftçi</td>
              </tr>
              <tr>
                <td>Ahmet SOFUOĞLU</td>
                <td>Tüccar</td>
              </tr>
              <tr>
                <td>Mahmut SÖKÜN</td>
                <td>Çiftçi</td>
              </tr>
              <tr>
                <td>Galip ŞENER</td>
                <td>Memur</td>
              </tr>
              <tr>
                <td>Talip TAŞÇI</td>
                <td>Diş Tabibi</td>
              </tr>
              <tr>
                <td>Kerim TEKİN</td>
                <td>Tüccar</td>
              </tr>
              <tr>
                <td>Sadettin TOKBEY</td>
                <td>Avukat</td>
              </tr>
              <tr>
                <td>Tahir TURANCIK</td>
                <td>Tüccar</td>
              </tr>
              <tr>
                <td>Faruk UĞURLU</td>
                <td>Tüccar</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Tarihce;
