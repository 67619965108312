import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import axios from "axios";
import Card from "react-bootstrap/Card";
import { useNavigate } from "react-router-dom";

const DuyuruCards = () => {
 
  const baseUrl =`${process.env.REACT_APP_BASE_URL}/announcement`;
  const [duyuru, setDuyuru] = useState([]);
  const navigate = useNavigate();

  //? Fetch  Data

  const fetchData = async () => {
    const response = await axios.get(baseUrl)
      .then(res=>res.data)
      .catch(error=>console.log(error))   
      setDuyuru(response.results);
  };


  useEffect(() => {  
    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const duyuruList = [];

  const moreClick = (e) => {   
    const duyuru_id = e.target.id;   
    navigate("/duyuruDetails", {state:{duyuru, duyuru_id}});
  }

  duyuru.map((item) =>
    duyuruList.push(
      <Card className="text-center mb-3  " key={item.id}>
        <Card.Header as="h5">{item.subject.subject}</Card.Header>
        <Card.Body >
          <Card.Title>{item.title}</Card.Title>
          <Button variant="warning " onClick={moreClick} id={item.id}>DETAYLAR</Button>
        </Card.Body>
        <Card.Footer className="text-muted">
          {item.updated_date.slice(8, 10)}.{item.updated_date.slice(5, 7)}.
          {item.updated_date.slice(0, 4)}
        </Card.Footer>
      </Card>
    )
  );

  return (
    <div className="m-auto m-5 border p-3" style={{ maxWidth: "40rem" }}>
      <h2 className="border-5 border-warning border-top border-bottom mt-3">
        HABERLER VE DUYURULAR
      </h2>  

      <div className="m-auto d-flex flex-column-reverse">
        {duyuruList.slice(duyuruList.length -3,duyuruList.length)}
      </div>

      <Button variant="danger" className="mb-3" onClick={() => navigate("/duyurular")}>
        {" "}
        tüm duyurular
      </Button>
    </div>
  );
};

export default DuyuruCards;
