import React from 'react'
import "./Footer.css"
import { GrInstagram  } from "react-icons/gr";
import { FaFax, FaPhoneAlt } from "react-icons/fa";
import { GoLocation } from "react-icons/go";
import { FiMail } from "react-icons/fi";


const Footer = () => {
  return (
    <div className='main'>
      <div className="instagram box m-auto">
        <a href="https://www.instagram.com/cukurovakulubudernegi/" target="blank"><GrInstagram className='icon'/></a> 
        <a href="https://www.instagram.com/cukurovakulubudernegi/" target="blank" className='text-decoration-none'><p className='link_p'> cukurovakulubudernegi </p> </a> 
        
      </div>
      <div className="location box">
        <a href="https://www.google.com.tr/maps/dir//36.9955425,35.3229537/@36.9961722,35.3213539,16.39z/data=!4m2!4m1!3e0?hl=tr" target="blank"><GoLocation className='icon'/> </a>
        <a href="https://www.google.com.tr/maps/dir//36.9955425,35.3229537/@36.9961722,35.3213539,16.39z/data=!4m2!4m1!3e0?hl=tr" target="blank" className='text-decoration-none'> <p className='link_p'>Adres : Çınarlı Mah. Cumhuriyet Cad. 55 Seyhan, Adana</p></a>
      
        <div className="address ">
            
        </div>
      </div>
      <div className="phone box">
        <FaPhoneAlt className='icon'/>
        <p className='paragraph'>Telefon : 0322 453 12 46</p>
      </div>
      <div className="fax box">
        <FaFax className='icon'/>
        <p className='paragraph'>Fax : 0322 458 72 51</p>
      </div>
      <div className="mail box">        
        <a href='mailto:cukurovakulubu@hotmail.com'><FiMail className='icon'/></a>
        <a href='mailto:cukurovakulubu@hotmail.com' className='text-decoration-none'><p className='link_p ' >E-Mail : cukurovakulubu@hotmail.com</p></a>
      </div>
      <div className="mail box">        
        <a href='mailto:cukurovakulubu@gmail.com'><FiMail className='icon'/></a>
        <a href='mailto:cukurovakulubu@gmail.com' className='text-decoration-none'><p className='link_p'> E-Mail : cukurovakulubu@gmail.com</p></a>
        
      </div>
      

    </div>
  )
}

export default Footer
