import React, { useState, useEffect } from "react";
import axios from "axios";
import Table from "react-bootstrap/Table";
import "./Hesap.css"

const Hesap = () => {
  const baseUrl = `${process.env.REACT_APP_BASE_URL}/accountnumbers`;
  const [hesaplar, setHesaplar] = useState([]);

  //? Fetch  Data
  const fetchData = async () => {
    const response = await axios
      .get(baseUrl)
      .then((res) => res.data)
      .catch((error) => console.log(error));
    setHesaplar(response.results);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={{ margin: "auto", width: "90vw", marginTop: "25vh" }}>
      <h1 className="header mt-5">HESAP NUMARALARI</h1>
      {hesaplar?.map((hesap) => (
        <div>
          <div key={hesap.id} className="accountpage_first">
            <Table
              striped
              bordered
              hover
              size="lg"
              className="center m-3"

            >
              <thead>
                <tr>
                  <th>HESAP ADI</th>
                  <th style={{ width: "40px" }}>BANKA</th>
                  <th style={{ width: "40px" }}>ŞUBE</th>
                  <th>ŞUBE KODU</th>
                  <th>HESAP NO</th>
                  <th>IBAN</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{hesap.account_name}</td>
                  <td>{hesap.bank_name}</td>
                  <td>{hesap.branch_name}</td>
                  <td>{hesap.branch_code}</td>
                  <td>{hesap.account_number}</td>
                  <td>{hesap.iban}</td>
                </tr>
              </tbody>
            </Table>
          </div>

          <div className="accountpage_second">
            <h5>Hesap Adı : {hesap.account_name}</h5>
            <h5>Banka : {hesap.bank_name}</h5>
            <h5>Şube : {hesap.branch_name}</h5>
            <h5>Şube Kodu : {hesap.branch_code}</h5>
            <h5>Hesap No :{hesap.account_number}</h5>
            <h5>İBAN:{hesap.iban}</h5>
          </div>
        </div>
      ))}

      <div className="m-5">
        <p style={{ fontSize: "20px" }}> *2023 YILI AİDATI (30.06.2023 tarihine kadar) : 3250 TL </p>
        <p style={{ fontSize: "20px" }}> *2023 YILI AİDATI (30.06.2023 tarihini sonrası) : 3750 TL </p>
        <p style={{ fontSize: "20px" }}>
          {" "}
          *Değerli Üyelerimiz; <br /> Lütfen aidat ödemesi yaparken açıklama
          kısmına isim soyisim ve hangi yılın aidat ödemesi olduğunu belirtiniz.
          <br />
          <br />
          <br />{" "}
        </p>
      </div>
    </div>
  );
};

export default Hesap;
