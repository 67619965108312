import React from 'react'
import Menu1 from "../../images/menuqr/Klüp Restoran Menü-1.jpg";
import Menu2 from "../../images/menuqr/Klüp Restoran Menü-2.jpg";
import Menu3 from "../../images/menuqr/Klüp Restoran Menü-3.jpg";
import Menu4 from "../../images/menuqr/Klüp Restoran Menü-4.jpg";
import Menu5 from "../../images/menuqr/Klüp Restoran Menü-5.jpg";
import Menu6 from "../../images/menuqr/Klüp Restoran Menü-6.jpg";
import Menu7 from "../../images/menuqr/Klüp Restoran Menü-7.jpg";

const RestoranQRMenu = () => {
  return (
    <div  className="qrmenu_container">
        <img src={Menu1} alt="qrmenu" className='qrmenu'/>
        <img src={Menu2} alt="qrmenu" className='qrmenu'/>
        <img src={Menu3} alt="qrmenu" className='qrmenu'/>
        <img src={Menu4} alt="qrmenu" className='qrmenu'/>
        <img src={Menu5} alt="qrmenu" className='qrmenu'/>
        <img src={Menu6} alt="qrmenu" className='qrmenu'/>
        <img src={Menu7} alt="qrmenu" className='qrmenu'/>
    </div>
  )
}

export default RestoranQRMenu