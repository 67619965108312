
import Maps from "../../images/harita.png";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

const Harita = () => {

  return (
    <OverlayTrigger 
    overlay={
      <Tooltip id="tooltip-top">
        Google Haritalar İçin Tıklayınız....
      </Tooltip>
    }
    >      
        
      
      <a
        href="https://www.google.com.tr/maps/dir//36.9955425,35.3229537/@36.9961722,35.3213539,16.39z/data=!4m2!4m1!3e0?hl=tr"
        target="blank"
        
      >
        <img
          src={Maps}
          alt="harita"
          style={{ width: "100vw", higth: "5rem" }}
          className="mt-5 mb-2 location"
        />
      </a>
    </OverlayTrigger>
  );
};

export default Harita;
