import React, { useState, useEffect } from "react";
import axios from "axios";
import Card from "react-bootstrap/Card";
import AvatarMan from "../../images/avatar_man.png";
import MemberCards from "../../components/member_cards/MemberCards";

const Yonetim = () => {
  const baseUrl = `${process.env.REACT_APP_BASE_URL}/commissions`;
  const [yonetim, setYonetim] = useState([]);

  //? Fetch  Data of Yonetim_Kurulu

  const fetchData = async () => {
    const response = await axios
      .get(baseUrl)
      .then((res) => res.data)
      .catch((error) => console.log(error));
    setYonetim(response.results);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={{ marginTop: "25vh" }}>
      <h1 className="header">YÖNETİM KURULU</h1>

      {yonetim
        ?.filter((member) => member.mission.status === "Başkan")
        .map((baskan) => (
          <div className="baskan d-flex justify-content-center" key={baskan.id}>
            <Card
              style={{ width: "18rem", height: "25rem" }}
              className={"card text-dark bg-light m-3 shadow"}
            >
              <Card.Img
                src={
                  baskan.profile_picture ? baskan.profile_picture : AvatarMan
                }
                className="card_img"
                style={{ width: "100%", height: "20rem" }}
              />
              <Card.Body>
                <Card.Title className="card_title">
                  {baskan.name} {baskan.surname}
                </Card.Title>
                <Card.Text className="card_text">Başkan</Card.Text>
              </Card.Body>
            </Card>
          </div>
        ))}

      <div className=" d-flex justify-content-center flex-wrap ">
        {yonetim
          .filter((member) => member.commission.commission === "YÖNETİM KURULU")
          .map((member) => (
            <MemberCards member={member} key={member.id} />
          ))}
      </div>
    </div>
  );
};

export default Yonetim;
