import React from "react";
import axios from "axios";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import AvatarMan from "../../images/avatar_man.png";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const BaskanCards = () => {
  const baseUrl = `${process.env.REACT_APP_BASE_URL}/commissions`;
  const navigate = useNavigate();

  // Bilgi Çağırma

  const [members, setMembers] = useState([]);

  //? Fetch  Data

  const fetchData = async () => {
    const response = await axios
      .get(baseUrl)
      .then((res) => res.data)
      .catch((error) => console.log(error));
    setMembers(response.results);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {members
        ?.filter((item) => item.mission.status === "Başkan")
        .map((member,index) => (
          <Card style={{ width: "16rem" }} key={index}>
            <Card.Img
              src={
                member.profile_picture ? member.profile_picture : AvatarMan
              }
              className="card_img"
              style={{ height: "14rem" }}
            />
            <Card.Body className="card_body">
              <h6>
                {member.name} {member.surname} <br />
                BAŞKAN
              </h6>
              <Button variant="danger" onClick={() => navigate("/baskanlar")}>
                ESKİ BAŞKANLARIMIZ
              </Button>
            </Card.Body>
          </Card>
        ))}
    </div>
  );
};

export default BaskanCards;
