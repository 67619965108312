import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Card from "react-bootstrap/Card";

const Duyurular = () => {
  const navigate = useNavigate();
  
  const baseUrl = `${process.env.REACT_APP_BASE_URL}/announcement`;
  const [duyuru, setDuyuru] = useState([]);

  //? Fetch  Data


  const fetchData = async () => {
    const response = await axios.get(baseUrl)
      .then(res=>res.data)
      .catch(error=>console.log(error))   
      setDuyuru(response.results);
  };


  useEffect(() => {
    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const moreClick = (e) => {   
    const duyuru_id = e.target.id;   
    navigate("/duyuruDetails", {state:{duyuru, duyuru_id}});
  }


  return (
    <div style={{ margin: "auto", maxWidth:"650px", marginTop: "24vh" }}>
      <h1 className="header mt-5">DUYURULAR</h1>
      <div className="m-auto d-flex flex-column-reverse">
        {duyuru.map((item) => (
          <Card className="text-center mb-3  " key={item.id}>
            <Card.Header as="h5">{item.subject.subject}</Card.Header>
            <Card.Body>
              <Card.Title>{item.title}</Card.Title>
              <Button variant="warning " onClick={moreClick} id={item.id}>DETAYLAR</Button>
            </Card.Body>
            <Card.Footer className="text-muted">
              {item.updated_date.slice(8, 10)}.{item.updated_date.slice(5, 7)}.
              {item.updated_date.slice(0, 4)}
            </Card.Footer>
          </Card>
        ))}        
      </div>
    </div>
  );
};

export default Duyurular;
