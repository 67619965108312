import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Organizasyonlar.css";

//! Bu sayfada fotoğrafların gösterilebilmesi için GALERİ kısmında girilen fotoğrafların küçük harflerle "organizasyon" adıyla eklenmesi gerekli

const Organizasyonlar = () => {
  const baseUrl = `${process.env.REACT_APP_BASE_URL}/galeri`;
  const [images, setImages] = useState([]);

  //? Fetch  Data


  const fetchData = async () => {
    const response = await axios.get(baseUrl)
      .then(res=>res.data)
      .catch(error=>console.log(error))   
      setImages(response.results);
  };

  useEffect(() => {
    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={{ marginTop: "25vh" }}>
      <h1 className="header">ORGANİZASYONLAR</h1>
      <div className="organization_container d-flex m-4">
        <div className="text" style={{ width: "500px", margin: "auto" }}>
          <h2 style={{ fontSize: "40px" }} className="text-danger">
            DÜĞÜN VE NİŞAN MERASİMLERİ
          </h2>
          <p style={{ fontSize: "20px" }}>
            72 yıllık tarihiyle Adana'mızın sosyal, kültürel, spor ve sanat
            hayatına yön veren Çukurova Kulübü gerek konumu, gerekse açık ve
            kapalı mekanlarıyla düğün ve nişan hazırlıklarına başlayan çiftler
            için muhteşem seçenekler sunmaktadır.
          </p>
          <p style={{ fontSize: "20px" }}>
            Detaylı bilgi ve rezervasyonlarınız için:{" "}
            <b> 0322 458 72 51 / 0533 447 04 43</b>
          </p>

          <h2 style={{ fontSize: "40px" }} className="text-danger">
            ETKİNLİKLER VE TOPLANTILAR
          </h2>
          <p style={{ fontSize: "20px" }}>
            Çukurova Kulübü toplantı ve etkinliklerinizin mükemmel geçmesi için
            gereken her türlü altyapıyı sunma ve kusursuz hizmet garantisi
            vermektedir.
          </p>
          <ul style={{ fontSize: "20px" }}>
            <li>Doğum Günü Etkinlikleri</li>
            <li>İş Yemekleri ve Toplantıları</li>
            <li>Kuruluş Yıldönümleri</li>
            <li>Özel Geceler</li>
            <li>VIP Grup Yemekleri</li>
            <li>Taziye Yemekleri</li>
          </ul>

          <p style={{ fontSize: "20px" }}>
            Detaylı bilgi ve rezervasyonlarınız için:{" "}
            <b> 0322 458 72 51 / 0533 447 04 43</b>
          </p>

        </div>
        <div>
          <div className=" d-flex justify-content-center flex-wrap ">
            {images
              .filter((filter_item) => filter_item.title.toLowerCase() === "organizasyon")
              .map((map_item) => (                
                <img src={map_item.image} alt="" className="foto" key={map_item.id} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Organizasyonlar;
