import React from 'react'
import BizeUlasın from '../../components/iletisim/BizeUlasın';
import Harita from '../../components/iletisim/Harita';
import IletisimForm from '../../components/iletisim/IletisimForm';




const Iletisim = () => {
  return (
    <div style={{ marginTop: "25vh" }}>
      <div className="bot d-flex justify-content-center flex-wrap">
        <div className='m-3' >
        <BizeUlasın/>
        </div>
        <div className='m-3'  style={{ borderLeft :'5px solid crimson',}}>
        <IletisimForm/> 
        </div>
      </div> 
      <Harita/>
    </div>
  )
}

export default Iletisim