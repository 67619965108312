import "./Navbar.css";
import React, {useState} from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../../images/logo.png";

function NavbarComp() {
  const navigate = useNavigate();
  const [isShow, setisShow] = useState(false)
  
  const handleClick = () =>{
    setisShow(!isShow)
    
  }
  return (
    <div
      bg="dark"
      expand="lg"
      variant="dark"
      className="navbar position-fixed top-0 start-0 end-0"
    >
      <div className="logo_div d-flex justify-content-around w-100">
        <img src={Logo} alt="logo" className="logo align-top" />
        <h2 className="nav_header" onClick={() => navigate("/")}>
          ÇUKUROVA KULÜBÜ DERNEĞİ <span>1950</span>
        </h2>
        <img src={Logo} alt="logo" className="logo align-top" />
        <div className="hamburger" onClick={handleClick}>
          <div className="hamburger_line"></div>
          <div className="hamburger_line"></div>
          <div className="hamburger_line"></div>
          <div className="hamburger_line"></div>
        </div>
      </div>

      
       
        <div className="hamburger_content" style={isShow ? {display:"block"} : {display:"none"}}>
          <div className=" hamburger_links" onClick={() => {navigate("/");handleClick()}}>
            ANASAYFA
          </div>
          <div className="hamburger_links" onClick={() => {navigate("/tarihce");handleClick()}}>
            TARİHÇE
          </div>
          <div className="hamburger_links" onClick={() => {navigate("/tuzuk");handleClick()}}>
            DOKÜMANLAR
          </div>

          <div className="dropdown">
          <div className="hamburger_links dropbtn">KURULLAR</div>
          <div className="dropdown-content kurullar_content">
            <div className="drop_links" onClick={() => {navigate("/yonetim");handleClick()}}>
              YÖNETİM KURULU
            </div>
            <div className="drop_links" onClick={() => {navigate("/kabul");handleClick()}}>
              KABUL KURULU
            </div>
            <div className="drop_links" onClick={() => {navigate("/denetleme");handleClick()}}>
              DENETLEME KURULU
            </div>
            <div className="drop_links" onClick={() => {navigate("/disiplin");handleClick()}}>
              DİSİPLİN KURULU
            </div>
            <div className="drop_links" onClick={() => {navigate("/baskanlar");handleClick()}}>
              BAŞKANLARIMIZ
            </div>
          </div>
        </div>

        <div className="hamburger_links" onClick={() => {navigate("/restoran");handleClick()}}>
          RESTORAN
        </div>
        <div className="hamburger_links" onClick={() => {navigate("/organizasyonlar");handleClick()}}>
          ORGANİZASYONLAR
        </div>
        <div className="hamburger_links" onClick={() => {navigate("/iletisim");handleClick()}}>
          İLETİŞİM
        </div>
        <div className="hamburger_links" onClick={() => {navigate("/hesap");handleClick()}}>
          HESAP NUMARALARI
        </div>
        <div className="hamburger_links" onClick={() => {navigate("/galeri");handleClick()}}>
          GALERİ
        </div>
              
            
          
        </div>
      

      <div
        className="navLinks w-100 d-flex justify-content-center"
        
      >
        <div className="nav_links" onClick={() => navigate("/")}>
          ANASAYFA
        </div>
        <div className="nav_links" onClick={() => navigate("/tarihce")}>
          TARİHÇE
        </div>
        <div className="nav_links" onClick={() => navigate("/tuzuk")}>
          DOKÜMANLAR
        </div>

        <div className="dropdown">
          <div className="nav_links dropbtn">KURULLAR</div>
          <div className="dropdown-content kurullar_content">
            <div className="drop_links" onClick={() => navigate("/yonetim")}>
              YÖNETİM KURULU
            </div>
            <div className="drop_links" onClick={() => navigate("/kabul")}>
              KABUL KURULU
            </div>
            <div className="drop_links" onClick={() => navigate("/denetleme")}>
              DENETLEME KURULU
            </div>
            <div className="drop_links" onClick={() => navigate("/disiplin")}>
              DİSİPLİN KURULU
            </div>
            <div className="drop_links" onClick={() => navigate("/baskanlar")}>
              BAŞKANLARIMIZ
            </div>
          </div>
        </div>

        <div className="nav_links" onClick={() => navigate("/restoran")}>
          RESTORAN
        </div>
        <div className="nav_links" onClick={() => navigate("/organizasyonlar")}>
          ORGANİZASYONLAR
        </div>
        <div className="nav_links" onClick={() => navigate("/iletisim")}>
          İLETİŞİM
        </div>
        <div className="nav_links" onClick={() => navigate("/hesap")}>
          HESAP NUMARALARI
        </div>
        <div className="nav_links" onClick={() => navigate("/galeri")}>
          GALERİ
        </div>
      </div>
    </div>
  );
}

export default NavbarComp;
