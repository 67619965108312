import React from "react";
import Card from "react-bootstrap/Card";
import AvatarMan from "../../images/avatar_man.png";


const MemberCards = ({ member }) => {
  return (
    <div >
      {
        member.id !== 1 ? 
      
      <Card  style={{ width: "18rem", height:"25rem", }} className={"card text-dark bg-light m-3 shadow"}>
        {member.profile_picture ? (
                    <Card.Img
                      variant="top"
                      alt="member_image"
                      src={member.profile_picture}
                      className="card_image"
                      style={{width:"100%",  height:"20rem"}}
                    />
                  )  : (
                    <Card.Img
                      variant="top"
                      alt="member_image"
                      src={AvatarMan}
                    />
                  )}
        <Card.Body>
          <Card.Title className="card_title">{member.name} {member.surname}</Card.Title>
          <Card.Text className="card_text">{member.mission.status}</Card.Text>
        </Card.Body>
      </Card>:""
      }
    </div>
  );
};

export default MemberCards;
