import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const IletisimForm = () => {
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [message, setMessage] = useState();

  // ! For warning
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  let updateForm = () => {
    setName("");
    setMessage("");
    setEmail("");
  };

  const addMessage = (info) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      name_surname: info.name,
      e_mail: info.email,
      message: info.message,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${process.env.REACT_APP_BASE_URL}/messages/`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        handleShow();
        updateForm();
      })
      .catch((error) => alert("Mesajınız gönderilemedi."));
  };

  const hadleSubmit = (e) => {
    e.preventDefault();
    addMessage({ name, email, message });
    setName("");
    setEmail("");
    setMessage("");
  };

  return (
    <div
      className="m-4 border-danger"
      style={{ width: "40rem", paddingLeft: "40px" }}
    >
      <h1 className="header"> MESAJINIZ </h1>
      <form className="m-3 p-3" onSubmit={hadleSubmit}>
        <Form.Group className="mb-3">
          <Form.Label>AD SOYAD</Form.Label>
          <Form.Control
            type="text"
            name="name_surname"
            onChange={(e) => setName(e.target.value)}
            value={name}
            required
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label>EMAİL ADRESİ</Form.Label>
          <Form.Control
            type="email"
            name="e_mail"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            required
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
          <Form.Label>MESAJINIZ</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            name="message"
            onChange={(e) => setMessage(e.target.value)}
            value={message}
            required
          />
        </Form.Group>
        <Button variant="danger" type="submit">
          GÖNDER
        </Button>
      </form>

      <Modal show={show} onHide={handleClose}>        
        <Modal.Body>Mesajınız Başarıyla Kaydedilmiştir!</Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose}>
            KAPAT
          </Button>         
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default IletisimForm;
