/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";
import "./Carousel.css";
import isLoading from "../../images/loading.gif";

const CarouselPage = () => {
  const baseUrl = `${process.env.REACT_APP_BASE_URL}/carrouselpanel`;
  
  const [carouselItems, setCarouselItems] = useState();
  const navigate = useNavigate();

  //? Fetch  Data

  const fetchData = async () => {
    const response = await axios.get(baseUrl)
      .then(res=>res.data)
      .catch(error=>console.log(error))   
    setCarouselItems(response.results.reverse());
  };

  useEffect(() => {
    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  

  const [index, setIndex] = useState(0);
  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };


//! For Carrousel Detail page path
  const moreClick = (e) => {
    const carrousel_id = e.target.id;
    navigate("/carrouselDetails", { state: { carouselItems, carrousel_id } });
  };

  return (
    <div>
      <Carousel
        activeIndex={index}
        onSelect={handleSelect}
        className="carousel_container"
      >
        {carouselItems ? (
          carouselItems.map((item, index) => (
            <Carousel.Item className="carousel_item" key={index}>
              <img
                className="d-block carousel_img "
                src={item.image}
                alt="First slide"
              />
              <Carousel.Caption onClick={moreClick}>
                <h2 className="carousel_header" id={item.id}>
                  {item.title}
                </h2>
              </Carousel.Caption>
            </Carousel.Item>
          ))
        ) : (
          <div className="m-auto pb-5 mb-5 rounded" style={{width:"100%", height:"100%"}}>
            <img
              src={isLoading}
              alt=""
              width={"200px"}
              className="d-flex justfy-content-center m-auto rounded"
            />            
          </div>
        )}
      </Carousel>
    </div>
  );
};

export default CarouselPage;
