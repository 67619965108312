/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import axios from "axios";
import AvatarMan from "../../images/avatar_man.png";
import Card from "react-bootstrap/Card";
import "./Baskanlar.css";

const Baskanlar = () => {
  const baseUrl = `${process.env.REACT_APP_BASE_URL}/presidents`;
  const [baskanlar, setBaskanlar] = useState([]);



  //? Fetch  Data

  const fetchData = async () => {
    const response = await axios.get(baseUrl)
      .then(res=>res.data)
      .catch(error=>console.log(error))   
      setBaskanlar(response.results);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="president_container ">
      <h1 className="header">ESKİ BAŞKANLARIMIZ</h1>
      <div className="first m-auto ">
        <div className="presidents mb-5 d-flex flex-column-reverse left_side">
          {baskanlar
            ?.filter((filter_item) =>
              baskanlar[baskanlar.length - 1].id % 2 === 0
                ? filter_item.id % 2 === 0
                : filter_item.id % 2 !== 0
            )
            .map((member) => (
              <div className="president_cards " key={member.id}>
                <div className="d-flex flex-column">                
                <Card.Text className="bg-danger text-white m-0 p-1">
                      {member.years}
                    </Card.Text>
                <Card className="cards">
                  {member.profile_picture ? (
                    <Card.Img
                      variant="top"
                      alt="member_image"
                      src={member.profile_picture}
                      className="card_image"
                    />
                  ) : (
                    <Card.Img
                      variant="top"
                      alt="member_image"
                      src={AvatarMan}
                    />
                  )}
                  <Card.Body>
                    <Card.Title>
                      {member.name} {member.surname}
                    </Card.Title>
                    
                  </Card.Body>
                </Card>
                </div>
                <div className="history_line" style={{marginTop:"13px", marginRight:"-1px"}}></div>
              </div>
            ))}
        </div>

        <div className="presidents mb-5 d-flex flex-column-reverse right_side">
          {baskanlar
            ?.filter((filter_item) =>
              baskanlar[baskanlar.length - 1].id % 2 === 0
                ? filter_item.id % 2 !== 0
                : filter_item.id % 2 === 0
            )
            .map((member) => (
              <div className="president_cards " key={member.id}>
                <div className="history_line" style={{marginTop:"13px", marginLeft:"-1px"}}></div>
                <div className="d-flex flex-column"> 
                <Card.Text className="bg-danger text-white m-0 p-1">
                      {member.years}
                    </Card.Text>               

                <Card className="cards">

                  {member.profile_picture ? (
                    <Card.Img
                      variant="top"
                      alt="member_image"
                      src={member.profile_picture}
                      className="card_image"
                    />
                  ) : (
                    <Card.Img
                      variant="top"
                      alt="member_image"
                      src={AvatarMan}
                    />
                  )}
                  <Card.Body>
                    <Card.Title>
                      {member.name} {member.surname}
                    </Card.Title>
                    
                  </Card.Body>
                </Card>
                </div>
              </div>
            ))}
        </div>
      </div>



{/* For litle screen one side   */}
      <div className=" second m-auto ">
        <div className="presidents mb-5 d-flex flex-column-reverse left_side">
          {baskanlar
            ?.map((member) => (
              <div className="president_cards mb-5" key={member.id}>
                <div className="d-flex flex-column">                
                <Card.Text className="bg-danger text-white m-0 p-1">
                      {member.years}
                    </Card.Text>
                <Card className="cards">
                  {member.profile_picture ? (
                    <Card.Img
                      variant="top"
                      alt="member_image"
                      src={member.profile_picture}
                      className="card_image"
                    />
                  )  : (
                    <Card.Img
                      variant="top"
                      alt="member_image"
                      src={AvatarMan}
                    />
                  )}
                  <Card.Body>
                    <Card.Title>
                      {member.name} {member.surname}
                    </Card.Title>
                    
                  </Card.Body>
                </Card>
                </div>
                <div className="history_line" style={{marginTop:"13px", marginRight:"-1px"}}></div>
              </div>
            ))}
        </div>

        
      </div>
    </div>
  );
};

export default Baskanlar;
