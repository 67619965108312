import React from 'react'

import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";

import Anasayfa from "../pages/anasayfa/Anasayfa"
import Denetleme from "../pages/denetleme/Denetleme"
import Disiplin from "../pages/disiplin/Disiplin"
import Galeri from "../pages/galeri/Galeri"
import Hesap from "../pages/hesap/Hesap"
import Iletisim from "../pages/iletisim/Iletisim"
import Kabul from "../pages/kabul/Kabul"
import Tuzuk from "../pages/tuzuk/Tuzuk"
import Yonetim from "../pages/yonetim/Yonetim"
import Restoran from "../pages/restoran/Restoran"
import Tarihce from "../pages/tarihce/Tarihce"
import Organizasyonlar from "../pages/organizasyonlar/Organizasyonlar"
import NavbarComp from "../components/navbar/NavbarComp"
import Footer from "../components/footer/Footer"
import Baskanlar from '../pages/baskanlar/Baskanlar';
import MenuDetailsCards from '../pages/restoran/MenuDetailsCards';
import Duyurular from '../pages/duyurular/Duyurular';
import DuyuruDetails from '../pages/duyurular/DuyuruDetails';
import CarrouselDetails from '../components/carousel/CarrouselDetails';
import RestoranQRMenu from '../pages/restoran/RestoranQRMenu';
import QrRestoran from '../pages/restoran/QrRestoran';
import QrMenuDetails from '../pages/restoran/QrMenuDetails';

const AppRouter = () => {
  return (
    <Router>
      <NavbarComp/>
      <Routes className="routes pt-3">
        <Route path = "/" element = {<Anasayfa/>} />
        <Route path = "/denetleme" element = {<Denetleme/>} />
        <Route path = "/disiplin" element = {<Disiplin/>} />
        <Route path = "/galeri" element = {<Galeri/>} />
        <Route path = "/hesap" element = {<Hesap/>} />
        <Route path = "/iletisim" element = {<Iletisim/>} />
        <Route path = "/kabul" element = {<Kabul/>} />
        <Route path = "/tuzuk" element = {<Tuzuk/>} />
        <Route path = "/tarihce" element = {<Tarihce/>} />
        <Route path = "/yonetim" element = {<Yonetim/>} />
        <Route path = "/restoran" element = {<Restoran/>} />
        <Route path = "/restoran/qrmenuqr" element = {<QrRestoran/>} />
        {/* <Route path = "/restoran/qrmenuqr" element = {<RestoranQRMenu/>} /> */}
        <Route path='/details' element = {<MenuDetailsCards/>} />
        <Route path='/details/qrmenuqr' element = {<QrMenuDetails/>} />
        <Route path = "/organizasyonlar" element = {<Organizasyonlar/>} />
        <Route path='/baskanlar' element = {<Baskanlar/>} />
        <Route path='/duyurular' element = {<Duyurular/>} />
        <Route path='/duyuruDetails' element = {<DuyuruDetails/>} />
        <Route path='/carrouselDetails' element = {<CarrouselDetails/>} />      
        
        
      </Routes>
      <Footer/>      
    </Router>
  )
}

export default AppRouter
