import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./Menu.css";
import { FiArrowLeftCircle, FiArrowRightCircle } from "react-icons/fi";
import { FaLiraSign } from "react-icons/fa";

// ? İMAGEs
import BaslangıcImg from "../../images/restoran/baslangıclar.jpg";
import AraSıcakImg from "../../images/restoran/arasıcaklar.jpg";
import SalataImg from "../../images/restoran/salatalar.jpg";
import EtImg from "../../images/restoran/et.jpg";
import BalıkImg from "../../images/restoran/balık.jpg";
import TavukImg from "../../images/restoran/tavuk.jpg";
import FırınImg from "../../images/restoran/fırın.jpg";
import SteakImg from "../../images/restoran/steak.jpg";
import IcecekImg from "../../images/restoran/icecekler.jpg";
import MeyveImg from "../../images/restoran/meyve.jpg";
import KahvaltıImg from "../../images/restoran/kahvaltı.jpg";

const MenuDetailsCards = () => {
  const location = useLocation();
  const { select_id } = location.state;
  const sel_id = Number(select_id);

  const navigate = useNavigate();
  const baseUrl = `${process.env.REACT_APP_BASE_URL}/restoran`;
  const [menu, setMenu] = useState([]);

  const data_list = [
    {
      id: 0,
      title: "BAŞLANGIÇ",
      img: BaslangıcImg,
      link: "/baslangic",
      filter_item: "BAŞ",
    },
    {
      id: 1,
      title: "ARA SICAKLAR",
      img: AraSıcakImg,
      link: "/arasicak",
      filter_item: "ARA",
    },
    {
      id: 2,
      title: "SALATALAR",
      img: SalataImg,
      link: "/salatalar",
      filter_item: "SAL",
    },
    { id: 3, title: "ET", img: EtImg, link: "/et", filter_item: "ET" },
    {
      id: 4,
      title: "BALIK",
      img: BalıkImg,
      link: "/balik",
      filter_item: "BAL",
    },
    {
      id: 5,
      title: "TAVUK",
      img: TavukImg,
      link: "/tavuk",
      filter_item: "TAV",
    },
    {
      id: 6,
      title: "FIRIN MENÜ",
      img: FırınImg,
      link: "/firin",
      filter_item: "FIR",
    },
    {
      id: 7,
      title: "STEAK MENÜ",
      img: SteakImg,
      link: "/steak",
      filter_item: "STE",
    },
    {
      id: 8,
      title: "İÇECEKLER",
      img: IcecekImg,
      link: "/icecekler",
      filter_item: "İÇE",
    },
    {
      id: 9,
      title: "MEYVE / TATLI",
      img: MeyveImg,
      link: "/meyve",
      filter_item: "MEY",
    },
    {
      id: 10,
      title: "KAHVALTI",
      img: KahvaltıImg,
      link: "/kahvalti",
      filter_item: "KAH",
    },
  ];

  //? Fetch  Data

  const fetchData = async () => {
    const response = await axios
      .get(baseUrl)
      .then((res) => res.data)
      .catch((error) => console.log(error));
    setMenu(response.results);
  };
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="out_container">
      <div className="menu_navbar d-flex flex-column w-100 pt-5">
        <div className="w-100 d-flex justify-content-between">
          {sel_id !== 0 ? (
            <h6
              className="head_2 "
              onClick={() =>
                navigate("/details", { state: { select_id: sel_id - 1 } })
              }
            >
              <FiArrowLeftCircle
                className="icon"
                style={{ width: "30px", height: "30px" }}
              />
              {data_list[select_id - 1].title}
            </h6>
          ) : (
            <h6 className="head_2 "> </h6>
          )}

          {sel_id !== 10 ? (
            <h6
              className="head_2 "
              onClick={() =>
                navigate("/details", { state: { select_id: sel_id + 1 } })
              }
            >
              {data_list[sel_id + 1].title}
              <FiArrowRightCircle
                className="icon"
                style={{ width: "30px", height: "30px", marginLeft: "10px" }}
              />
            </h6>
          ) : (
            <h6 className="head_2"> </h6>
          )}
        </div>

        <h1 className="header head_1 ">{data_list[select_id].title}</h1>
      </div>

      <img src={data_list[select_id].img} alt="menu_img" className="menu_img" />

      <div className="menus">
        {menu
          .filter(
            (title) => title.order_type === data_list[select_id].filter_item
          )
          .map((item, index) => (
            <div className="eats" key={index}>
              <div className="menu_line">
                <div className="eat_name">{item.title}</div>
                {/* {item.price ? <div className="eat_name">{item.price} <FaLiraSign/></div>  : ""} */}
              </div>
              <div className="text_context">{item.comment}</div>
            </div>
          ))}

        <div className="text-white mt-5">
          <p>* Servis Elemanından Bilgi Alınız.</p>
          <h5>REZERVASYON : 0533 447 0443</h5>
        </div>
      </div>
    </div>
  );
};

export default MenuDetailsCards;
