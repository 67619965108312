import React from 'react'
import { GrInstagram  } from "react-icons/gr";
import { GoLocation } from "react-icons/go";
import { FiMail } from "react-icons/fi";

const BizeUlasın = () => {
  return (
    <div className='m-4'>
        <h1 className="header"> BİZE ULAŞIN </h1>
        <div>
            <a href="https://www.instagram.com/cukurovakulubudernegi/" 
              target="blank"
              ><GrInstagram className='icon' style={{ width: "60px", height:"60px", }}/></a>
            <a href="https://www.google.com.tr/maps/dir//36.9955425,35.3229537/@36.9961722,35.3213539,16.39z/data=!4m2!4m1!3e0?hl=tr" 
            target="blank"
            ><GoLocation className='icon hover-zoom' style={{ width: "60px", height:"50px", }}/></a>
            <a href='mailto:cukurovakulubu@hotmail.com'
                         
            ><FiMail className='icon' style={{ width: "60px", height:"60px", }} /></a>
        </div>
        <div className='m-3'>
            <p style={{fontSize:"1.3rem"}}>ÇINARLI MAHALLESİ CUMHURİYET CADDESİ NO:55 SEYHAN/ADANA</p>
            <p style={{fontSize:"1.3rem"}}>TELEFON : 0322 453 12 46</p>
            <p style={{fontSize:"1.3rem"}}>RESTORAN : 0533 447 04 43</p>
            <p style={{fontSize:"1.3rem"}}>FAX : 0322 458 72 51</p>
            <p style={{fontSize:"1.3rem"}}>INSTAGRAM : "cukurovakulubudernegi"</p>
            <p style={{fontSize:"1.3rem"}}>EMAİL : cukurovakulubu@hotmail.com</p>
            <p style={{fontSize:"1.3rem"}}>EMAİL : cukurovakulubu@gmail.com</p>
        </div>
    </div>
  )
}

export default BizeUlasın