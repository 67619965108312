import React from "react";
import { useLocation, useNavigate  } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Logo from "../../images/logo.png";
import isLoading from "../../images/loading.gif"

const CarrouselDetails = () => {

  const navigate = useNavigate();
  const location = useLocation(); 
  const { carouselItems } = location.state;
  const { carrousel_id } = location.state;
 


  return (
    <div
      className="d-flex justify-content-center flex-column"
      style={{ margin: "auto", maxWidth: "800px", marginTop: "22vh" }}
    >
      <div>
        {carouselItems ? 
          carouselItems.filter((title) => title.id == carrousel_id)
          .map((item) => (
            <div className="m-auto  p-3 m-3" key={item.id}>
              <div className="d-flex justify-content-between">
                <Button variant="secondary" className="m-3" onClick={() => navigate("/duyurular")} style={{ width: "10rem" }}>
                  {" "}
                  TÜM DUYURULAR                  
                </Button>
                <Button variant="secondary" className="m-3" onClick={() => navigate("/galeri")} style={{ width: "10rem" }}>
                  {" "}
                  GALERİ
                </Button>
              </div>
              <div>
                <img
                  src={item.image ? item.image : Logo}
                  alt=""
                  style={{ width: "350px", height: "270px" }}
                />
              </div>
              <div className="m-3">
                <h4>{item.title}</h4>
                <hr />
                <p>{item.comment}</p>
              </div>
              <div className="mt-4">
                <h5 className=" bg-secondary p-2 rounded text-white ">
                  {item.updated_date.slice(8, 10)}.
                  {item.updated_date.slice(5, 7)}.
                  {item.updated_date.slice(0, 4)}
                </h5>
              </div>
            </div>
          )) : <img src={isLoading} alt="" width={"50px"}/>}
      </div>     
    </div>
  );
};

export default CarrouselDetails;
