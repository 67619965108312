import React, { useState, useEffect } from "react";
import axios from "axios";
import MemberCards from "../../components/member_cards/MemberCards";

const Denetleme = () => {
  const baseUrl = `${process.env.REACT_APP_BASE_URL}/commissions`;
  const [denetleme, setDenetleme] = useState([]);

  //? Fetch  Data 

  const fetchData = async () => {
    const response = await axios.get(baseUrl)
      .then(res=>res.data)
      .catch(error=>console.log(error))   
      setDenetleme(response.results);
  };

  useEffect(() => {
    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  
  return (
    <div  style={{ marginTop: "24vh" }}>
      <h1 className="header">DENETLEME KURULU</h1>
      <div className="baskan d-flex justify-content-center">
      </div>
      <div className=" d-flex justify-content-center flex-wrap ">
        {denetleme.filter((member) => member.commission.commission === "DENETLEME KURULU" ).map((member) => (
          <MemberCards member={member} key={member.id} />
        ))}
      </div>
    </div>
  );
};

export default Denetleme;
